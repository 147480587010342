.m_b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  max-width: var(--notifications-container-width);
}

  .m_b37d9ac7:where([data-position='top-center']) {
    top: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='top-left']) {
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='top-right']) {
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-center']) {
    bottom: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='bottom-left']) {
    bottom: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-right']) {
    bottom: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

.m_5ed0edd0 + .m_5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }

/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/dcc209c0b1ab30af-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/dcc209c0b1ab30af-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/dcc209c0b1ab30af-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ae80e08d9fcae03a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/4486f70b101e60d9-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3e57fe4abb1c4cae-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_85695c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/dcc209c0b1ab30af-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Playfair_Display_Fallback_85695c';src: local("Times New Roman");ascent-override: 97.25%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 111.26%
}.__className_85695c {font-family: '__Playfair_Display_85695c', '__Playfair_Display_Fallback_85695c';font-style: normal
}

